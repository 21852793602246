* {
  margin: 0;
  padding: 0;
}

@font-face {
  font-family: "Proxima Nova Regular";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Regular.otf");
}

@font-face {
  font-family: "Proxima Nova Bold";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/Mark Simonson  Proxima Nova Bold.otf");
}

@font-face {
  font-family: "Silika Black";
  font-style: normal;
  font-weight:300;
  font-display: swap;
  src: url("./assets/fonts/silka-light-webfont.ttf");
}

@font-face {
  font-family: "Silika Black";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url("./assets/fonts/silka-black-webfont.ttf");
}

@font-face {
  font-family: "Silika Black";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url("./assets/fonts/silka-bold-webfont.ttf");
}
/*
*
* @TODO to be removed
*/
@font-face {
  font-family: "Silika Light";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/silka-light-webfont.ttf");
  font-weight: 600;
}

@font-face {
  font-family: "Silika Medium";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/silka-medium-webfont.ttf");
}

@font-face {
  font-family: "Silika Black Regular";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/silka-black-webfont.ttf");
}

@font-face {
  font-family: "Silika Black Bold";
  font-style: normal;
  font-display: swap;
  src: url("./assets/fonts/silka-bold-webfont.ttf");
}
